<template>
  <validation-observer :ref="refName" tag="form">
    <slot></slot>
  </validation-observer>
</template>
<script lang="ts">
import Vue from 'vue'
import { ValidationObserver } from 'vee-validate'
import Component from 'vue-class-component'
import { Prop } from 'vue-property-decorator'

@Component({
  components: { ValidationObserver }
})
export default class VForm extends Vue {
  @Prop({ type: String, default: 'form' }) refName?: string

  validate (): Function {
    // @ts-ignore
    return this.$refs[this.refName].validate()
  }

  reset (): Function {
    // @ts-ignore
    return this.$refs[this.refName].reset()
  }
}
</script>
