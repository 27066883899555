<template>
  <validation-provider :name="fieldId" :rules="validation || validationObj" v-slot="{ errors }" slim mode="lazy">
    <div class="form-group  checkbox-field" :class="{ 'has-error': errors.length }">
      <b-checkbox :class="cssClass"
                  :name="fieldId"
                  v-model="innerValue">
        <slot name="label"></slot>
        {{ label }}
      </b-checkbox>
      <p class="text-danger" v-if="errors.length">{{ errors[0] }}</p>
    </div>
  </validation-provider>
</template>
<script lang="ts">
import Vue from 'vue'
import { ValidationProvider } from 'vee-validate'
import Component from 'vue-class-component'
import { Prop, Watch } from 'vue-property-decorator'

@Component({
  components: { ValidationProvider }
})
export default class CheckboxField extends Vue {
  innerValue: boolean = false

  @Prop({ type: Boolean, default: false }) value!: boolean
  @Prop({ type: String, required: false }) label!: string
  @Prop({ type: String, required: true }) fieldId!: string
  @Prop({ type: String, default: '' }) validation?: string
  @Prop({ type: Object, default: () => {} }) validationObj!: any
  @Prop({ type: String }) cssClass?: string
  @Prop({ type: Boolean, default: false }) middle?: boolean
  @Prop({ type: Boolean, default: false }) reverse?: boolean

  @Watch('innerValue')
  onInnerValueChange (value: boolean) {
    this.$emit('input', this.reverse ? !value : value)
  }

  @Watch('value')
  onValueChange (value: boolean) {
    this.innerValue = this.reverse ? !value : value
  }

  created () {
    this.innerValue = this.reverse ? !this.value : this.value
  }
}
</script>
