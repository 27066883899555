import { ActionTree } from 'vuex'
import RootState from '@/store/rootState'
import {
  APP_LOADING_DATA_DISABLE,
  APP_LOADING_DATA_ENABLE,
  APP_SUBMIT_PROTECTION_DISABLE,
  APP_SUBMIT_PROTECTION_ENABLE
} from './appMutationTypes'
import AppState from '@/modules/app/store/AppState'

const actions: ActionTree<AppState, RootState> = {
  async protect ({ commit }) {
    await commit(APP_SUBMIT_PROTECTION_ENABLE)
  },
  async unprotect ({ commit }) {
    await commit(APP_SUBMIT_PROTECTION_DISABLE)
  },
  async loadingDataEnable ({ commit }, id: string) {
    await commit(APP_LOADING_DATA_ENABLE, id)
  },
  async loadingDataDisable ({ commit }, id: string) {
    await commit(APP_LOADING_DATA_DISABLE, id)
  }
}

export default actions
