import _ from 'lodash'
import AuthState from '@/modules/auth/store/authState'
import { GetterTree } from 'vuex'
import RootState from '@/store/rootState'

const authGetters: GetterTree<AuthState, RootState> = {
  loggedIn: (state: AuthState) => {
    return !!state.accessToken && !!state.user
  },
  data: (state: AuthState) => {
    return _.pick(state, ['accessToken', 'accessTokenValidTo', 'refreshToken', 'refreshTokenValidTo', 'fcmToken', 'user'])
  }
}
export default authGetters
