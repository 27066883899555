import { API_ROOT } from '@/config'
import { DOWNLOAD_BLOB } from '@/utils'
import { HttpResponse } from 'vue-resource/types/vue_resource'
import platformService from '@/modules/common/services/platformService'
import Vue from 'vue'
import runAuthorizedRequest from '@/auth/oauthUtils'

async function downloadBinary (url: string, fileName: string) {
  await runAuthorizedRequest(() => (<any>Vue).http.get(url, { responseType: 'blob' }))
    // resolve to Blob
    .then((response: HttpResponse) => response.blob())
    .then((data: Blob) => DOWNLOAD_BLOB(data, fileName))
}

export default {
  async downloadPass (customerId: string, companyId: string) {
    // apple pass need to be downloaded as binary file
    if (platformService.apple()) {
      await downloadBinary(`${API_ROOT}customers/${customerId}/companies/${companyId}/pass/apple`, 'stammgast-pass.pkpass')
    } else {
      let errResponse = null
      const response = await runAuthorizedRequest(() => (<any>Vue).http.get(`${API_ROOT}customers/${customerId}/companies/${companyId}/pass/google`))
        .catch((reason: HttpResponse) => {
          errResponse = reason
        })
      if (errResponse || !response) {
        return null
      } else {
        await window.open(response.data.fat_pay_pass)
      }
    }
  }
}
