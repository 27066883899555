import _ from 'lodash'
import Credentials from '@/modules/auth/domain/credentials'
import { Route } from 'vue-router'
import { API_ROOT, COMPANY_PARAM, FACEBOOK_ACCESS_TOKEN_URL, FACEBOOK_LOGIN_URL, LOGIN_URL, REDIRECT_TO_PARAM, REFRESH_TOKEN_URL } from '@/config'
import { Dictionary } from '@/utils/types'
import { SignUpCredentials } from '@/modules/auth/domain/signUpCredentials'
import PasswordResetRequestCredentials from '@/modules/auth/domain/passwordResetRequestCredentials'
import Response, { OK } from '@/modules/common/domain/response'
import PasswordResetCredentials from '@/modules/auth/domain/passwordResetCredentials'
import Vue from 'vue'

const SIGN_UP_URL = 'customers/register'
const SIGN_UP_CONFIRM_URL = 'customers/email-verify/{code}'
const PASSWORD_RESET_REQUEST_URL = 'customers/reset-password-request'
const PASSWORD_RESET_URL = 'customers/reset-password'
const PASSWORD_LOGOUT = 'auth/logout'
const AUTH_BASIC_HEADERS = {
  'Content-Type': 'application/x-www-form-urlencoded',
  Accept: 'application/json'
}

export default {
  async login (credentials: Credentials) {
    try {
      const data = new FormData()
      data.set('username', <string>credentials.username)
      data.set('password', <string>credentials.password)
      data.set('captchaToken', <string>credentials.captchaToken)
      const response = await (<any>Vue).http.post(LOGIN_URL, data, { headers: AUTH_BASIC_HEADERS })
      return Response.fromHttpResponse(response)
    } catch (err) {
      return Response.fromHttpResponse(err)
    }
  },
  async refreshToken (refreshToken: string) {
    try {
      const data = new FormData()
      data.set('refresh_token', refreshToken)
      const response = await (<any>Vue).http.post(REFRESH_TOKEN_URL, data, { headers: AUTH_BASIC_HEADERS })
      return Response.fromHttpResponse(response)
    } catch (err: any) {
      return Response.fromHttpResponse(err.response)
    }
  },
  async loginFacebook (code: string, companyId?: string) {
    try {
      const response = await (<any>Vue).http.post(FACEBOOK_ACCESS_TOKEN_URL + '?code=' + code + (companyId ? `&company=${companyId}` : ''), {})
      return Response.fromHttpResponse(response)
    } catch (err) {
      return Response.fromHttpResponse(err)
    }
  },
  async redirectToFacebook (companyName?: string) {
    let url = FACEBOOK_LOGIN_URL
    // add companyId to url if was passed
    if (companyName) {
      url += `?${COMPANY_PARAM}=${companyName}`
    }
    window.open(url, '_self')
    // const name = 'facebook_login'
    // const specs = 'width=500,height=500'
    // const loginWindow = window.open(url, name, specs)
  },
  async singUp (credentials: SignUpCredentials, params: Dictionary<String>) {
    try {
      await (<any>Vue).http.post(API_ROOT + SIGN_UP_URL, credentials, { params })
      return OK
    } catch (err) {
      return Response.fromHttpResponse(err as any)
    }
  },
  async sigInConfirmation (code: string) {
    const response = await (<any>Vue).http.post(API_ROOT + SIGN_UP_CONFIRM_URL, null, { params: { code } })
    return response.ok
  },
  async resetPassword (credentials: PasswordResetCredentials) {
    const response = await (<any>Vue).http.post(API_ROOT + PASSWORD_RESET_URL, _.omit(credentials, 'email'))
    return response.ok
  },
  async resetPasswordRequest (credentials: PasswordResetRequestCredentials) {
    try {
      await (<any>Vue).http.post(API_ROOT + PASSWORD_RESET_REQUEST_URL, credentials)
      return OK
    } catch (err) {
      return Response.fromHttpResponse(err as any)
    }
  },
  loginPath (route: Route) {
    const path = '/preface'
    const query = {} as any
    if (route.query[REDIRECT_TO_PARAM]) {
      query[REDIRECT_TO_PARAM] = route.query[REDIRECT_TO_PARAM]
    } else if (['preface', 'login', 'signUp'].indexOf(route.name || '') < 0) {
      query[REDIRECT_TO_PARAM] = encodeURI(route.path)
    }
    if (route.query[COMPANY_PARAM]) {
      query[COMPANY_PARAM] = encodeURI(route.query[COMPANY_PARAM] as string)
    }
    return { path, query }
  },
  mainPath (currentRoute: Route) {
    const query = {} as any
    if (currentRoute.query[COMPANY_PARAM]) {
      query[COMPANY_PARAM] = currentRoute.query[COMPANY_PARAM]
    }
    const redirectToVal = currentRoute.query[REDIRECT_TO_PARAM]
    const path = redirectToVal ? decodeURI(<string>redirectToVal) : '/'
    return { path, query }
  },
  async logout () {
    try {
      await (<any>Vue).http.post(API_ROOT + PASSWORD_LOGOUT)
      return OK
    } catch (err) {
      return Response.fromHttpResponse(err as any)
    }
  }
}
