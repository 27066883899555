  <template>
  <div class="container">
    <login-header></login-header>
    <public-page-box v-if="success">
      <h5 class="text-center">{{ $t('password.reset.success.title', credentials) }}</h5>
      <p class="text-center mb-0">{{ $t('password.reset.success.text') }}</p>
    </public-page-box>
    <public-page-box v-else>
      <h5 class="text-center">{{ $t('password.reset.title') }}</h5>
      <v-form class="form-signin" ref="form">
        <text-field field-id="username"
                    label="signup.email.label"
                    placeholder="signup.email.placeholder"
                    :maxlength="100"
                    disabled
                    v-model="credentials.email"></text-field>
        <text-field field-id="newPassword"
                    label="signup.password.label"
                    placeholder="signup.password.placeholder"
                    v-model="credentials.password"
                    :maxlength="50"
                    type="password"
                    validation="required|min:5|max:50"></text-field>
        <text-field field-id="confirmPassword"
                    label="signup.confirmPassword.label"
                    placeholder="signup.confirmPassword.placeholder"
                    v-model="credentials.passwordConfirm"
                    :maxlength="50"
                    type="password"
                    validation="required|confirmed:newPassword"></text-field>
        <loading id="password-reset"></loading>
        <button class="btn btn-lg btn-success btn-block text-uppercase btn-form" :disabled="protected()" type="submit"
                @click.prevent="submit($refs.form)" v-t="'button.submit'">
        </button>
      </v-form>
    </public-page-box>
    <public-page-box v-if="success">
      <router-link :to="{ name: 'login' }" class="btn btn-lg btn-default btn-block text-uppercase btn-form" tag="button"
                   v-t="'password.reset.request.back'"></router-link>
    </public-page-box>
  </div>
</template>

<script lang="ts">
import { CODE_PARAM } from '@/config'
import Component, { mixins } from 'vue-class-component'
import { jwtDecode } from 'jwt-decode'
import loading from '@/modules/common/components/loading.vue'
import loginHeader from '@/modules/app/components/loginHeader.vue'
import publicPageBox from '@/modules/app/components/publicPageBox.vue'
import textField from '@/modules/common/components/form/textField.vue'
import submitProtectionMixin from '@/modules/common/mixins/submitProtectionMixin'
import validationMixin from '@/modules/common/components/form/validationMixin'
import vForm from '@/modules/common/components/form/vForm.vue'
import PasswordResetCredentials from '@/modules/auth/domain/passwordResetCredentials'

@Component({
  components: { loading, textField, loginHeader, publicPageBox, vForm }
})
export default class PasswordResetRequest extends mixins(submitProtectionMixin, validationMixin) {
  credentials = new PasswordResetCredentials()
  success = false

  async onSubmit () {
    await this.$store.dispatch('app/loadingDataEnable', 'password-reset')

    await this.$recaptchaLoaded()
    // Execute reCAPTCHA with action "login".
    this.credentials.captchaToken = await this.$recaptcha('resetPassword')

    this.success = await this.$store.dispatch('auth/resetPassword', this.credentials)

    await this.$store.dispatch('app/loadingDataDisable', 'password-reset')
  }

  created () {
    if (this.$route.query[CODE_PARAM]) {
      this.credentials.passwordResetCode = this.$route.query[CODE_PARAM] as string
      const decoded = jwtDecode(this.$route.query[CODE_PARAM] as string) as any
      this.credentials.email = decoded.email
    }
  }
}
</script>
