<template>
  <b-modal id="facebookInfoFullScreenModal"
           modal-class="full-screen-modal"
           size="md"
           hide-header
           hide-footer
           no-close-on-backdrop
           no-close-on-esc>
    <div v-if="isApple">
      <p class="text-center" >{{ $t('pass.detail.facebookBrowser.not.supported.apple') }}</p>
      <p class="text-center" v-if="locale">
        <img :src="require(`./../../../img/open-in-safari_${locale}.gif`)" class="w-100"/>
      </p>
    </div>
    <div v-else>
      <p class="text-center" >{{ $t('pass.detail.facebookBrowser.not.supported.android') }}</p>
      <p class="text-center" v-if="locale">
        <img :src="require(`./../../../img/open-in-chrome_${locale}.gif`)" class="w-100"/>
      </p>
    </div>
  </b-modal>
</template>

<script lang="ts">
import Component from 'vue-class-component'
import i18n from '@/i18n/i18n'
import platformService from '@/modules/common/services/platformService'
import Vue from 'vue'

@Component
export default class FacebookInfoModal extends Vue {
  locale: string | null = null

  get isApple () {
    return platformService.apple()
  }

  mounted () {
    this.locale = i18n.locale

    if (platformService.embeddedBrowser()) {
      this.$bvModal.show('facebookInfoFullScreenModal')
    }
  }
}
</script>
