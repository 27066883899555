import _ from 'lodash'
import { Entity } from '@/modules/common/domain/entity'
import { LoginMethodType } from '@/modules/customer/type/LoginMethodType'

export default class Customer extends Entity {
  password?: string | null
  firstname?: string | null
  surname?: string | null = null
  email?: string | null
  loginMethods: Array<LoginMethodType> = []
  conditionConfirmed?: boolean
  personalDataConfirmed?: boolean

  constructor (data: any) {
    super(data)
    this.firstname = data.firstname
    this.surname = data.surname
    this.email = data.email
    if (data.loginMethods) {
      this.loginMethods = data.loginMethods.map((loginMethod: any) => (<any>LoginMethodType)[loginMethod])
    }
    this.conditionConfirmed = data.conditionConfirmed
    this.personalDataConfirmed = data.personalDataConfirmed
  }

  get fullName (): string {
    return !_.isNil(this.firstname) && !_.isNil(this.surname) ? (this.firstname + ' ' + this.surname) : this.email + ''
  }

  get hasPasswordLogin () {
    return this.loginMethods && this.loginMethods.indexOf(LoginMethodType.PASSWORD) >= 0
  }
}
