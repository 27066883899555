import passDetailPage from '@/modules/pass/components/passDetailPage.vue'
import passesPage from '@/modules/pass/components/passesPage.vue'

export default [
  {
    name: 'passes',
    path: '/passes',
    component: passesPage,
    meta: {
      requiresLoggedIn: true
    }
  },
  {
    name: 'passDetail',
    path: '/passes/:companyUrlName',
    component: passDetailPage,
    props: true,
    meta: {
      requiresLoggedIn: true
    }
  },
  {
    name: 'passDownload',
    path: '/pass',
    component: passDetailPage,
    props: true,
    meta: {
      requiresLoggedIn: true
    }
  }
]
