import Vue from 'vue'
// init resource lib need to be done before other imports
import './resourceInit'
import '@/modules/common/directives'
import router from './router'
import store from './store'
import filters from '@/modules/common/filters'
import interceptors from '@/interceptors'
import hooks from '@/hooks'
import 'jquery'
import BootstrapVue from 'bootstrap-vue'
import App from './modules/app/components/app.vue'
import validationsInit from './modules/common/validation'
import notificationService from './modules/common/services/notificationService'
import { VueReCaptcha } from 'vue-recaptcha-v3'
import { RECAPTCHA_PUBLIC_KEY } from '@/config'
import i18n from './i18n/i18n'
import firebaseService from '@/firebase/firebaseService'
import urlCleanHook from '@/hooks/urlCleanHook'
import smartlookService from '@/modules/common/services/smartlookService'
import VueMeta from 'vue-meta'
import { initOauth } from '@/auth/oauthUtils'

// first step, clean invalid URL, e.g. facebook fbclid etc. Continue only if url was not cleared
if (!urlCleanHook()) {
  smartlookService.init(store)

  Vue.use(BootstrapVue)

  firebaseService.init()

  validationsInit()

  filters()

  // init recaptcha
  Vue.use(VueReCaptcha, { siteKey: RECAPTCHA_PUBLIC_KEY })

  // register http interceptor
  interceptors(store, router)

  // register hooks
  hooks(store, router)

  // init auth resource
  initOauth(store)

  Vue.config.productionTip = false

  Vue.use(VueMeta)

  new Vue({
    i18n,
    router,
    store,
    render: h => h(App)
  }).$mount('#app')

  notificationService.init()
}
