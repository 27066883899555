import actions from './customerActions'
import mutations from '../../common/store/entityMutations'
import { Module } from 'vuex'
import RootState from '@/store/rootState'
import EntityState from '@/modules/common/store/entityState'
import Customer from '@/modules/customer/domain/customer'

const userModule: Module<EntityState<Customer>, RootState> = {
  namespaced: true,
  state: new EntityState<Customer>(),
  mutations,
  actions
}
export default userModule
