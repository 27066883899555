import customerService from '../services/customerService'
import entityActions from '@/modules/common/store/entityActions'
import firebaseService from '@/firebase/firebaseService'

const actions = entityActions(customerService, 'user')

// eslint-disable-next-line no-empty-pattern
actions.subscribeToNotifications = async function ({}, data) {
  try {
    const token = await firebaseService.getFcmToken()
    if (token) {
      await customerService.persistFcmToken(data.uuid, token, data.companyUrlName)
    }
  } catch (ex) {
    // console.error(ex)
  }
}

export default actions
