export const API_ROOT = process.env.VUE_APP_API_ROOT
export const ACCESS_TOKEN_URL = API_ROOT + 'auth/access-token'
export const REFRESH_TOKEN_URL = API_ROOT + 'auth/refresh-token'
export const LOGIN_URL = ACCESS_TOKEN_URL
export const FACEBOOK_LOGIN_URL = API_ROOT + 'auth/facebook/'
export const FACEBOOK_ACCESS_TOKEN_URL = API_ROOT + 'auth/access-token-fb'
export const FACEBOOK_CALLBACK_PATH = '/login/facebook'
export const COMPANY_PARAM = 'company'
export const REDIRECT_TO_PARAM = 'redirectTo'
export const PASS_ROUTE = 'pass'
export const CODE_PARAM = 'code'
export const AUTH_STORAGE_KEY = 'stammgast_auth_data'
export const RECAPTCHA_PUBLIC_KEY = process.env.VUE_APP_RECAPTCHA_PUBLIC_KEY
export const FCM_PUBLIC_KEY = process.env.VUE_APP_FCM_PUBLIC_KEY
export const SMARTLOOK_ENABLED = process.env.VUE_APP_SMARTLOOK_ENABLED === 'true'
export const SMARTLOOK_API_KEY = process.env.VUE_APP_SMARTLOOK_API_KEY
export const LOCALE = 'cs'

export const FIREBASE_CONFIG = {
  apiKey: process.env.VUE_APP_FIREBASE_CONFIG_API_KEY,
  authDomain: process.env.VUE_APP_FIREBASE_CONFIG_AUTH_DOMAIN,
  databaseURL: process.env.VUE_APP_FIREBASE_CONFIG_DATABASE_URL,
  projectId: process.env.VUE_APP_FIREBASE_CONFIG_PROJECT_ID,
  storageBucket: process.env.VUE_APP_FIREBASE_CONFIG_STORAGE_BUCKET,
  messagingSenderId: process.env.VUE_APP_FIREBASE_CONFIG_MESSAGING_SENDER_ID,
  appId: process.env.VUE_APP_FIREBASE_CONFIG_APP_ID,
  measurementId: process.env.VUE_APP_FIREBASE_CONFIG_MEASUREMENT_ID
}
