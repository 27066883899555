<template>
  <page title="passwordChange.title">
    <div class="row justify-content-center">
      <div class="col">
        <v-form ref="form">
          <text-field field-id="originalPassword"
                      label="passwordChange.originalPassword.label"
                      placeholder="passwordChange.originalPassword.placeholder"
                      v-model="credentials.originalPassword"
                      :maxlength="20"
                      type="password"
                      validation="required|min:5|max:20"></text-field>
          <text-field field-id="newPassword"
                      label="passwordChange.newPassword.label"
                      placeholder="passwordChange.newPassword.placeholder"
                      v-model="credentials.newPassword"
                      :maxlength="20"
                      iput-ref="newPassword"
                      type="password"
                      data-vv-value-path="value"
                      data-vv-name="newPassword"
                      validation="required|min:5|max:20"></text-field>
          <text-field field-id="confirmPassword"
                      label="passwordChange.confirmPassword.label"
                      placeholder="passwordChange.confirmPassword.placeholder"
                      v-model="credentials.confirmPassword"
                      :maxlength="20"
                      type="password"
                      data-vv-value-path="value"
                      data-vv-name="confirmPassword"
                      validation="required|confirmed:newPassword"></text-field>
          <button class="btn btn-lg btn-primary btn-block text-uppercase btn-form" :disabled="protected()" type="submit"
                  @click.prevent="submit($refs.form)" v-t="'button.save'">
          </button>
        </v-form>
      </div>
    </div>
  </page>
</template>

<script lang="ts">
import Component, { mixins } from 'vue-class-component'
import page from '@/modules/common/components/page.vue'
import vForm from '@/modules/common/components/form/vForm.vue'
import textField from '@/modules/common/components/form/textField.vue'
import validationMixin from '@/modules/common/components/form/validationMixin'
import notificationService from '@/modules/common/services/notificationService'
import customerService from '@/modules/customer/services/customerService'
import PasswordChange from '@/modules/customer/domain/passwordChange'
import { State } from 'vuex-class'
import Customer from '@/modules/customer/domain/customer'

@Component({
  components: { page, textField, vForm }
})
export default class PasswordChangePage extends mixins(validationMixin) {
  @State('user', { namespace: 'auth' }) currentUser!: Customer
  credentials?: PasswordChange | null = new PasswordChange()

  async onSubmit () {
    try {
      await customerService.myPasswordChange(this.currentUser.uuid!, this.credentials!)
      notificationService.success('passwordChange.change.success')
      this.credentials = new PasswordChange()
      await this.reset(this.$refs.form)
      this.$emit('close')
    } finally {
      await this.unprotect()
    }
  }
}
</script>
