import { Entity } from '@/modules/common/domain/entity'

export default class Company extends Entity {
  name?: string
  urlName?: string
  terms?: string
  bgColor?: string
  logoUrl?: string
  constructor (data: any) {
    super(data, 'name')
    this.name = data.name
    this.urlName = data.urlName
    this.terms = data.terms
    this.bgColor = data.bgColor
    this.logoUrl = data.logoUrl
    this.extendSearchString()
  }

  extendSearchString () {
    this.searchString = this.name || ''
  }
}
