<template>
  <div v-if="user">
    <b-navbar toggleable="lg" type="dark" variant="primary">
      <b-navbar-brand to="/">
        <img src="./../../../img/logo-full-white-web.png"/>
      </b-navbar-brand>

      <b-navbar-toggle target="nav-collapse"></b-navbar-toggle>

      <b-collapse id="nav-collapse" is-nav>
        <b-navbar-nav>
          <b-nav-item to="/passes" active-class="active">{{ $t('navbar.passes') }}</b-nav-item>
        </b-navbar-nav>

        <b-navbar-nav class="ml-auto">
          <b-nav-item to="/profile" active-class="active">{{ $t('navbar.profile') }}</b-nav-item>
          <b-nav-item to="/password-change" v-if="user.hasPasswordLogin" active-class="active">{{ $t('navbar.passwordChange') }}</b-nav-item>
          <b-nav-item @click.prevent="logout()">{{ $t('navbar.logout') }}</b-nav-item>
        </b-navbar-nav>
      </b-collapse>
    </b-navbar>
  </div>
</template>
<script lang="ts">
import Vue from 'vue'
import Customer from '@/modules/customer/domain/customer'
import Component from 'vue-class-component'
import { State } from 'vuex-class'

@Component
export default class Navbar extends Vue {
  @State('user', { namespace: 'auth' }) user!: Customer

  async logout () {
    await this.$store.dispatch('auth/logOut')
    location.reload()
  }
}
</script>
