import 'firebase/messaging'
import { SMARTLOOK_API_KEY, SMARTLOOK_ENABLED } from '@/config'
import { Store } from 'vuex'
import AuthState from '@/modules/auth/store/authState'

export default {
  async init (store: Store<any>) {
    if (SMARTLOOK_ENABLED) {
      // @ts-ignore
      // eslint-disable-next-line
      window.smartlook || (function (d) {
        const o: any = (window as any).smartlook = function () {
          // eslint-disable-next-line prefer-rest-params
          o.api.push(arguments)
        }
        const h = (<any>d).getElementsByTagName('head')[0]
        const c = (<any>d).createElement('script')
        o.api = []
        c.async = true
        c.type = 'text/javascript'
        c.charset = 'utf-8'
        c.src = 'https://rec.smartlook.com/recorder.js'
        h.appendChild(c)
      })(document);
      (window as any).smartlook('init', SMARTLOOK_API_KEY)
      const auth = store.state.auth as AuthState
      if (auth.user) {
        this.identify(auth.user!.email!)
      }
    }
  },
  identify (email: string) {
    if (SMARTLOOK_ENABLED) {
      (window as any).smartlook('identify', email)
    }
  }
}
