import { render, staticRenderFns } from "./passwordReset.vue?vue&type=template&id=8db5e64a"
import script from "./passwordReset.vue?vue&type=script&lang=ts"
export * from "./passwordReset.vue?vue&type=script&lang=ts"


/* normalize component */
import normalizer from "!../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports