<template>
  <div class="container">
    <login-header></login-header>
    <public-page-box>
      <h4 class="text-center" v-t="'signup.label'"></h4>

      <div class="row" v-if="!showSuccess">
        <div class="col">
          <button class="btn btn-lg btn-facebook btn-block text-uppercase btn-form" type="button" @click.stop="loginInFacebook()" :disabled="protected()">
            <i class="fab fa-facebook-f mr-2"></i> {{ $t('login.submit.facebook') }}
          </button>
        </div>
      </div>

      <div class="row my-3" v-if="!showSuccess">
        <div class="col"> <hr> </div>
        <div class="col-auto text-uppercase pt-1 text-muted" v-t="'login.submit.separator'"></div>
        <div class="col"> <hr> </div>
      </div>

      <p class="mt-4" v-if="showSuccess" v-t="'signup.finished.text'"></p>
      <b-alert variant="danger" :show="response && !response.success" v-html="$t(response.error || '')" v-if="response" class="text-center mb-0"></b-alert>
      <v-form class="form-signin pt-3" ref="form" v-if="!showSuccess">
        <text-field field-id="firstname"
                    label="signup.firstname.label"
                    placeholder="signup.firstname.placeholder"
                    :maxlength="100"
                    v-model.trim="credentials.firstname"
                    validation="required|min:3|max:100"></text-field>
        <text-field field-id="surname"
                    label="signup.surname.label"
                    placeholder="signup.surname.placeholder"
                    :maxlength="100"
                    v-model.trim="credentials.surname"
                    validation="required|min:3|max:100"></text-field>
        <text-field field-id="username"
                    label="signup.email.label"
                    placeholder="signup.email.placeholder"
                    :maxlength="100"
                    v-model.trim="credentials.email"
                    validation="required|min:5|max:100"></text-field>
        <text-field field-id="newPassword"
                    label="signup.password.label"
                    placeholder="signup.password.placeholder"
                    v-model="credentials.password"
                    :maxlength="50"
                    type="password"
                    validation="required|min:5|max:50"></text-field>
        <text-field field-id="confirmPassword"
                    label="signup.confirmPassword.label"
                    placeholder="signup.confirmPassword.placeholder"
                    v-model="credentials.passwordConfirm"
                    :maxlength="50"
                    type="password"
                    validation="required|confirmed:newPassword"></text-field>
        <checkbox-field field-id="conditionConfirmed" v-model="credentials.conditionConfirmed" :validation-obj="{ required: { allowFalse: false } }">
          <template #label>
            {{ $t('signup.agreement.conditions.prefix') }} <b-link v-b-modal.agreementConditionsModal>{{ $t('signup.agreement.conditions.link') }}</b-link>
          </template>
        </checkbox-field>
        <checkbox-field field-id="personalDataConfirmed" v-model="credentials.personalDataConfirmed" :validation-obj="{ required: { allowFalse: false } }">
          <template #label>
            {{ $t('signup.agreement.personalData.prefix') }} <b-link v-b-modal.agreementPersonalModal>{{ $t('signup.agreement.personalData.link') }}</b-link>
          </template>
        </checkbox-field>
        <button class="btn btn-lg btn-success btn-block text-uppercase btn-form"
                :disabled="protected()"
                @click.prevent="submit($refs.form)" v-t="'signup.submit'">
        </button>
      </v-form>
      <loading class="text-center mt-3" id="signUp" variant="success"/>

      <b-modal id="agreementConditionsModal"
               size="xl"
               :ok-only="true"
               :ok-title="$t('info.button.ok')"
               :title="$t('signup.agreement.conditions.modal.title')">
        <p v-html="$t('signup.agreement.conditions.modal.content')"></p>
      </b-modal>

      <b-modal id="agreementPersonalModal"
               size="xl"
               :ok-only="true"
               :ok-title="$t('info.button.ok')"
               :title="$t('signup.agreement.personalData.modal.title')">
        <p v-html="$t('signup.agreement.personalData.modal.content')"></p>
      </b-modal>
    </public-page-box>
</div>
</template>
<script lang="ts">

import { COMPANY_PARAM } from '@/config'
import Component, { mixins } from 'vue-class-component'
import CheckboxField from '@/modules/common/components/form/checkboxField.vue'
import loading from '@/modules/common/components/loading.vue'
import loginHeader from '@/modules/app/components/loginHeader.vue'
import submitProtectionMixin from '@/modules/common/mixins/submitProtectionMixin'
import textField from '@/modules/common/components/form/textField.vue'
import validationMixin from '@/modules/common/components/form/validationMixin'
import vForm from '@/modules/common/components/form/vForm.vue'
import PublicPageBox from '@/modules/app/components/publicPageBox.vue'
import { SignUpCredentials } from '@/modules/auth/domain/signUpCredentials'
import Response from '@/modules/common/domain/response'
import authService from '@/modules/auth/services/authService'

@Component({
  components: { PublicPageBox, CheckboxField, loading, loginHeader, textField, vForm }
})
export default class Home extends mixins(validationMixin, submitProtectionMixin) {
  credentials: SignUpCredentials = new SignUpCredentials()
  response: Response | null = null

  async loginInFacebook () {
    await this.protect()
    await this.$store.dispatch('app/loadingDataEnable', 'login')
    await authService.redirectToFacebook(this.$route.query[COMPANY_PARAM] as string)
  }

  get showSuccess () {
    return this.response && this.response.success
  }

  async onSubmit () {
    await this.$store.dispatch('app/loadingDataEnable', 'signUp')

    await this.$recaptchaLoaded()
    // Execute reCAPTCHA with action "login".
    this.credentials.captchaToken = await this.$recaptcha('signUp')

    // add companyId to url if was passed
    const params = {} as any
    if (this.$route.query[COMPANY_PARAM]) {
      params[COMPANY_PARAM] = this.$route.query[COMPANY_PARAM]
    }

    this.response = await this.$store.dispatch('auth/signUp', { credentials: this.credentials, params }) as Response
    await this.$store.dispatch('app/loadingDataDisable', 'signUp')
  }
}
</script>
