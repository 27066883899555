import _ from 'lodash'
import { GetterTree } from 'vuex'
import RootState from '@/store/rootState'
import EntityState from '@/modules/common/store/entityState'

const getters: GetterTree<EntityState<any>, RootState> = {
  active: (state) => {
    return _.filter((state.items) || [], 'active')
  },
  all: (state) => {
    return state.items || []
  },
  first: (state) => {
    return _.first(state.items)
  }
}

export default getters
