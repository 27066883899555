<template>
  <div class="container">
    <login-header></login-header>
    <public-page-box v-if="response && response.success">
      <h5 class="text-center">{{ $t('password.reset.request.success.title') }}</h5>
      <p class="text-center mb-0" v-html="$t('password.reset.request.success.text', credentials)"></p>
    </public-page-box>
    <public-page-box v-else>
      <h5 class="text-center">{{ $t('password.reset.request.title') }}</h5>
      <p class="text-center">{{ $t('password.reset.request.text') }}</p>
      <b-alert variant="danger" :show="response && response.error" v-html="$t(response.error || '')" v-if="response" class="text-center mb-0"></b-alert>
      <v-form class="form-signin pt-3" ref="form">
        <text-field field-id="email"
                    label="login.email.label"
                    placeholder="login.email.placeholder"
                    :maxlength="100"
                    :disabled="protected()"
                    v-model="credentials.email"
                    validation="required|min:5|max:100|email"></text-field>
        <button class="btn btn-lg btn-primary btn-block text-uppercase btn-form" :disabled="protected()" type="submit"
                @click.prevent="submit($refs.form)" v-t="'password.reset.request.submit'">
        </button>
        <loading id="password-reset" class="mt-4"></loading>
      </v-form>
    </public-page-box>
    <public-page-box v-if="!response || !response.success">
      <router-link :to="{ name: 'login' }" class="btn btn-lg btn-default btn-block text-uppercase btn-form" tag="button" v-t="'password.reset.request.back'"></router-link>
    </public-page-box>
  </div>
</template>

<script lang="ts">
import Component, { mixins } from 'vue-class-component'
import loading from '@/modules/common/components/loading.vue'
import loginHeader from '@/modules/app/components/loginHeader.vue'
import PasswordResetRequestCredentials from '@/modules/auth/domain/passwordResetRequestCredentials'
import publicPageBox from '@/modules/app/components/publicPageBox.vue'
import Response from '@/modules/common/domain/response'
import textField from '@/modules/common/components/form/textField.vue'
import submitProtectionMixin from '@/modules/common/mixins/submitProtectionMixin'
import validationMixin from '@/modules/common/components/form/validationMixin'
import vForm from '@/modules/common/components/form/vForm.vue'

@Component({
  components: { loading, textField, loginHeader, publicPageBox, vForm }
})
export default class PasswordResetRequest extends mixins(submitProtectionMixin, validationMixin) {
  credentials = new PasswordResetRequestCredentials()
  response: Response | null = null

  async onSubmit () {
    await this.$store.dispatch('app/loadingDataEnable', 'password-reset')

    await this.$recaptchaLoaded()
    // Execute reCAPTCHA with action "login".
    this.credentials.captchaToken = await this.$recaptcha('resetPasswordRequest')

    this.response = await this.$store.dispatch('auth/resetPasswordRequest', this.credentials) as Response
    await this.$store.dispatch('app/loadingDataDisable', 'password-reset')
  }
}
</script>
