export default {
  granted () {
    return Notification && Notification.permission === 'granted'
  },
  available () {
    return Notification && Notification.permission === 'default'
  },
  async request () {
    if (Notification) {
      return await Notification.requestPermission()
    } else {
      throw Error('Web notification are not supported.')
    }
  }
}
