// hook for cleaning damaged url e.g. with fbclid from facebook chat: https://alfa-acc.smartbrains.cz/?fbclid=IwAR2tLpSv5cQgChp2EWRoFa877WkF00hdLhm2saQGEX-M4HfC_wi7LTgY-Gk#/login?redirectTo=%2Fhome
import { COMPANY_PARAM, FACEBOOK_CALLBACK_PATH, PASS_ROUTE } from '@/config'

export default () => {
  // callback url from facebook login is based on "path" = API_URL/login-progress, because with "hash" callback "API_URL/#/login-progress
  // "FCB construct damaged URL e.g. "http://localhost:4000/?code=AQBKXKTD...ueBkFlC#/login-progress?company=test1
  if (window.location.pathname === FACEBOOK_CALLBACK_PATH) {
    window.location.assign(window.location.origin + '/#/login-progress' + window.location.search)
    return true
  } else if (window.location.search) {
    const location = window.location.origin + window.location.pathname + window.location.hash
    window.location.assign(location)
    return true
    // fix for instagram - replace name of company from path name and add it to pass route parameter
  } else if (window.location.pathname && window.location.pathname.match(/[/\\].+/)) {
    const companyName = window.location.pathname.substring(1)
    window.location.assign(`${window.location.origin}/#/${PASS_ROUTE}?${COMPANY_PARAM}=${companyName}`)
    return true
  }
  return false
}
