import { getMessaging, getToken } from 'firebase/messaging'
import { FCM_PUBLIC_KEY, FIREBASE_CONFIG } from '@/config'
import platformService from '@/modules/common/services/platformService'
import { initializeApp } from 'firebase/app'

export default {
  app: undefined,

  init () {
    if (!platformService.apple() && !platformService.embeddedBrowser()) {
      this.app = <any>initializeApp(FIREBASE_CONFIG)
    }
  },

  async getFcmToken () {
    try {
      const messaging = await getMessaging(this.app)
      return await getToken(messaging, { vapidKey: FCM_PUBLIC_KEY })
    } catch (e) {
      return null
    }
  }
}
