import { MutationTree } from 'vuex'
import { CLEAR_ALL, CLEAR_SELECTED, LIST, SELECT } from './entityMutationTypes'
import EntityState from './entityState'

const mutations: MutationTree<EntityState<any>> = {
  [LIST] (state, action: any) {
    state.items = action.items
    state.parameters = action.parameters
  },
  [SELECT] (state, action: any) {
    state.selected = action.item
  },
  [CLEAR_ALL] (state) {
    state.items = []
    state.parameters = null
  },
  [CLEAR_SELECTED] (state) {
    state.selected = null
  }
}

export default mutations
