<template>
  <div>
    <div class="row">
      <div class="col-12 mt-5 text-center">
        <img src="./../../../img/logo-full-white.png"/>
      </div>
    </div>
    <div class="row">
      <div class="col-12">
        <p class="text-center" v-html="$t('login.description')"></p>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import Component from 'vue-class-component'
import Vue from 'vue'

@Component
export default class LoginHeader extends Vue {
}
</script>
