export class SignUpCredentials {
  firstname: string | null = null
  surname: string | null = null
  email: string | null = null
  password: string | null = null
  passwordConfirm: string | null = null
  conditionConfirmed = false
  personalDataConfirmed = false
  captchaToken?: string
}
