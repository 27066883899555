import loginView from '../components/login.vue'
import prefaceView from '../components/preface.vue'
import loginProgressView from '../components/loginProgress.vue'
import signUpView from '../components/signUp.vue'
import passwordReset from '../components/passwordReset.vue'
import passwordResetRequest from '../components/passwordResetRequest.vue'

export default [
  {
    path: '/',
    redirect: '/passes',
    meta: {
      requiresLoggedIn: true
    }
  },
  {
    name: 'preface',
    path: '/preface',
    component: prefaceView,
    meta: {
      bodyClass: 'public-page'
    }
  },
  {
    name: 'login',
    path: '/login',
    component: loginView,
    meta: {
      bodyClass: 'public-page'
    }
  },
  {
    name: 'loginProgress',
    path: '/login-progress',
    component: loginProgressView,
    meta: {
      bodyClass: 'public-page'
    }
  },
  {
    name: 'signUp',
    path: '/sign-up',
    component: signUpView,
    meta: {
      bodyClass: 'public-page'
    }
  },
  {
    name: 'passwordResetRequest',
    path: '/password-reset-request',
    component: passwordResetRequest,
    meta: {
      bodyClass: 'public-page'
    }
  },
  {
    name: 'passwordReset',
    path: '/password-reset',
    component: passwordReset,
    meta: {
      bodyClass: 'public-page'
    }
  }
]
