<template>
  <page header-class="transparent-header" :card-style="'background-color: ' + (company ? company.bgColor : 'transparent') + ';'">
    <template #header>
      <div class="float-left mt-2" v-if="!registration">
        <router-link class="btn text-white" tag="button" to="/passes"><i class="fas fa-chevron-left"></i></router-link>
      </div>
      <div class="text-center" v-if="company"><img :src="company.logoUrl"></div>
    </template>
    <template #default>
      <v-form ref="form">
        <div class="row">
          <div class="col text-center">
            <loading id="company-select"></loading>
          </div>
        </div>
        <div v-if="company">
          <loading id="companyApply-select" class="text-center"></loading>
          <div class="row" v-if="companyApply">
            <div class="col">
              <p class="text-center">
                <small class="text-muted"> {{ $t('pass.detail.text.1', { date: formatDate(companyApply.created) }) }}</small>
              </p>
            </div>
          </div>
          <div class="row">
            <div class="col text-center" v-if="registration && !embeddedBrowser">

              <p class="">
                <b-link v-b-modal.agreementConditionsModal >{{ $t('pass.detail.conditions.registration') }}</b-link>
              </p>

              <checkbox-field field-id="agreementPersonalData" v-model="conditionsAgreement" :validation-obj="{ required: { allowFalse: false } }">
                <template #label>
                  {{ $t('pass.detail.conditions.registration.agree.label') }}
                </template>
              </checkbox-field>
            </div>
            <div class="col" v-else>
              <h2 class="text-center">{{ company.name }}</h2>
              <p class="text-center">
                <b-link v-b-modal.agreementConditionsModal>{{ $t('pass.detail.conditions.link') }}</b-link>
                {{ $t('pass.detail.conditions.suffix') }}
              </p>
            </div>
          </div>
          <div class="row justify-content-center">
            <div class="col" v-if="isApple">
              <div class="text-center">
                <button class="btn btn-link btn-apple-wallet"
                        type="button"
                        @click.stop="submit($refs.form, 'downloadPass')"
                        :disabled="protected()">
                  <img src="./../../../img/addToAppleWallet_cs.svg"/>
                </button>
              </div>
            </div>
            <div class="col" v-else>
              <div class="text-center">
                <button class="m-0 p-0 btn btn-link btn-google-pay"
                        type="button"
                        @click.stop="submit($refs.form, 'downloadGooglePass')"
                        :disabled="protected()">
                  <img src="./../../../img/addToGooglePay_cs.svg"/>
                </button>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col text-center">
              <loading id="pass-download"></loading>
            </div>
          </div>
        </div>
        <b-modal id="agreementConditionsModal"
                 size="xl"
                 :ok-only="true"
                 :ok-title="$t('info.button.ok')"
                 :title="$t('pass.detail.agreement.conditions.modal.title')">
          <p class="text-pre-wrap" v-if="company" v-html="company.terms "></p>
        </b-modal>
        <b-modal id="notificationRequestFullScreenModal"
                 v-model="notificationRequestShowModal"
                 size="md"
                 :hide-header="true"
                 ok-variant="success"
                 :hide-header-close="true"
                 :no-close-on-backdrop="true"
                 :no-close-on-esc="true"
                 footer-class="d-block"
                 cancel-variant="outline-danger"
                 :ok-title="$t('pass.detail.notification.modal.ok.label')"
                 :cancel-title="$t('pass.detail.notification.modal.cancel.label')"
                 class="notification-request-modal"
                 @ok="subscribeAndDownload"
                 @cancel="downloadPass">
          <div class="text-center">
            <img src="./../../../img/notification.gif"/>
          </div>
          <p class="text-center" v-html="$t('pass.detail.notification.modal.content')"></p>
          <template #modal-footer="{ ok, cancel }">
            <b-button variant="primary" @click="ok()" :block="true">
              {{ $t('pass.detail.notification.modal.ok.label') }}
            </b-button>
            <b-button variant="default" @click="cancel()" :block="true">
              {{ $t('pass.detail.notification.modal.cancel.label') }}
            </b-button>
          </template>
        </b-modal>
      </v-form>
    </template>
  </page>
</template>

<script lang="ts">

import _ from 'lodash'
import Component, { mixins } from 'vue-class-component'
import Company from '@/modules/company/domain/company'
import CompanyApply from '@/modules/company/domain/companyApply'
import { COMPANY_PARAM } from '@/config'
import EntityFetchParams from '@/modules/common/store/entityFetchParams'
import facebookInfoModal from '@/modules/common/components/facebookInfoModal.vue'
import { FORMAT_DATE } from '@/utils'
import checkboxField from '@/modules/common/components/form/checkboxField.vue'
import i18n from '@/i18n/i18n'
import loading from '@/modules/common/components/loading.vue'
import moment from 'moment'
import page from '@/modules/common/components/page.vue'
import passService from '@/modules/pass/services/passService'
import platformService from '@/modules/common/services/platformService'
import webNotificationService from '@/modules/common/services/webNotificationService'
import { Prop } from 'vue-property-decorator'
import { State } from 'vuex-class'
import submitProtectionMixin from '@/modules/common/mixins/submitProtectionMixin'
import validationMixin from '@/modules/common/components/form/validationMixin'
import vForm from '@/modules/common/components/form/vForm.vue'
import Customer from '@/modules/customer/domain/customer'

@Component({
  components: { checkboxField, facebookInfoModal, loading, page, vForm }
})
export default class PassDetailPage extends mixins(submitProtectionMixin, validationMixin) {
  registration = false
  conditionsAgreement = false
  notificationRequestShowModal = false
  companyId?: string | null = null
  locale: string | null = null

  @State('user', { namespace: 'auth' }) currentUser!: Customer
  @State('selected', { namespace: 'company' }) company!: Company
  @State('selected', { namespace: 'companyApply' }) companyApply!: CompanyApply
  @State('items', { namespace: 'company' }) companies!: Array<Company>
  @Prop({ type: String, required: false }) companyUrlName!: string

  get embeddedBrowser () {
    return platformService.embeddedBrowser()
  }

  get isApple () {
    return platformService.apple()
  }

  get confirmed () {
    return !this.registration || this.conditionsAgreement
  }

  /**
   * Download card - universal for both platform (apple/google)
   */
  async downloadPass () {
    await this.protect()
    await this.$store.dispatch('app/loadingDataEnable', 'pass-download')
    await passService.downloadPass(this.currentUser.uuid!, this.company.uuid!)
    await this.$store.dispatch('app/loadingDataDisable', 'pass-download')
    await this.unprotect()
    if (this.registration) {
      this.registration = false
      await this.$router.push({ name: 'passDetail', params: { companyUrlName: this.company.urlName! } })
    }
  }

  /**
   * Download google pass. It ensures web push notification permission request, subscribe to company topic and sending token to BE
   */
  async downloadGooglePass () {
    await this.protect()

    await this.$store.dispatch('app/loadingDataEnable', 'pass-download')
    if (webNotificationService.granted()) {
      await this.subscribeAndDownload()
    } else if (webNotificationService.available()) {
      this.notificationRequestShowModal = true
    } else {
      // in case of denied notification, only download the pass
      await this.downloadPass()
    }
  }

  /**
   * Download google pass. It ensures web push notification permission request, subscribe to company topic and sending token to BE.
   */
  async subscribeAndDownload () {
    try {
      await webNotificationService.request()
      await this.$store.dispatch('customer/subscribeToNotifications', { uuid: this.currentUser.uuid, companyUrlName: this.company.urlName })
    } finally {
      await this.downloadPass()
    }
  }

  formatDate (date?: moment.Moment) {
    return FORMAT_DATE(date)
  }

  async mounted () {
    this.locale = i18n.locale
    await this.$store.dispatch('company/clearSelected')
    await this.$store.dispatch('company/getAll', new EntityFetchParams(true))
    if (this.companyUrlName) {
      await this.$store.dispatch('company/select', { urlName: this.companyUrlName })
      await this.$store.dispatch('companyApply/select', { uuid: this.currentUser.uuid, companyUuid: this.company.uuid })
    } else if (this.$route.query[COMPANY_PARAM]) {
      await this.$store.dispatch('company/select', { urlName: this.$route.query[COMPANY_PARAM] })
      // show registration elements only if company is not registered
      if (!_.some(this.companies, { uuid: this.company.uuid })) {
        this.registration = true
      }
    }
  }
}
</script>
