import _ from 'lodash'
import { Entity, GENERATED_PROPS } from '@/modules/common/domain/entity'
import EntityResource from '@/modules/common/services/entityResource'
import runAuthorizedRequest from '@/auth/oauthUtils'

export default class EntityService<T extends Entity> {
  resource: EntityResource
  ConstructorFn: new (data: any) => T

  constructor (repository: EntityResource, constructorFn: new (data: any) => T) {
    this.resource = repository
    this.ConstructorFn = constructorFn
  }

  async find (params: any) {
    try {
      const response = await runAuthorizedRequest(() => this.resource.get(params))
      return response.ok ? this.createItem(response.data) : null
    } catch (e) {
      return null
    }
  }

  async findAll (queryParams: any) {
    try {
      const response = await runAuthorizedRequest(() => this.resource.query(queryParams))
      return response.ok ? response.data.map((itemData: any) => this.createItem(itemData)) : null
    } catch (e) {
      return null
    }
  }

  async create (item: T) {
    try {
      const response = await runAuthorizedRequest(() => this.resource.save({}, _(item).omit(GENERATED_PROPS).omitBy(_.isNil).value()))
      return response.ok ? this.createItem(response.data || {}) : null
    } catch (e) {
      return null
    }
  }

  async update (item: T) {
    try {
      const response = await runAuthorizedRequest(() => this.resource.update({ uuid: item.uuid }, _(item).omit(GENERATED_PROPS).omitBy(_.isNil).value()))
      return response.ok ? this.createItem(response.data) : null
    } catch (e) {
      return null
    }
  }

  async delete (uuid: any) {
    try {
      return await runAuthorizedRequest(() => this.resource.delete({ uuid }))
    } catch (e) {
      return e
    }
  }

  createItem (data: any) {
    return new this.ConstructorFn(data)
  }
}
