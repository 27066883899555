import AuthState from '@/modules/auth/store/authState'
import moment from 'moment/moment'
import { Store } from 'vuex'
import { HttpResponse } from 'vue-resource/types/vue_resource'

let store: Store<any>
export function initOauth (appStore: Store<any>) {
  store = appStore
}

async function checkTokens () {
  const auth = store.state.auth as AuthState
  if (auth.accessToken) {
    const isAccessTokenValid = auth.accessTokenValidTo?.isAfter(moment().add(10, 's'))
    const isRefreshTokenValid = auth.refreshTokenValidTo?.isAfter(moment())
    if (!isAccessTokenValid && isRefreshTokenValid) {
      await store.dispatch('auth/refreshToken')
    }
  }
}

export default async function runAuthorizedRequest (request: Function): Promise<HttpResponse> {
  await checkTokens()
  return await request() as Promise<HttpResponse>
}
