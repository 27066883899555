import codeListValueFilter from '@/modules/common/filters/codeListValueFilter'
import dateTimeFilter from './dateTimeFilter'
import dateFilter from './dateFilter'
import timeFilter from './timeFilter'
import translate from './translateFilter'
import Vue from 'vue'

export default () => {
  Vue.filter('codeListValue', codeListValueFilter)
  Vue.filter('date', dateFilter)
  Vue.filter('dateTime', dateTimeFilter)
  Vue.filter('time', timeFilter)
  Vue.filter('translate', translate)
}
