<template>
  <div class="row mb-4">
    <div class="col-sm-9 col-md-7 col-lg-5 mx-auto">
      <b-card class="card-signin">
        <slot></slot>
      </b-card>
    </div>
  </div>
</template>

<script lang="ts">
import Component from 'vue-class-component'
import Vue from 'vue'

@Component
export default class PublicPageBox extends Vue {
}
</script>
