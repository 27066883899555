import moment from 'moment'
import Customer from '@/modules/customer/domain/customer'

export default class AuthState {
  accessToken: string | null
  accessTokenValidTo: moment.Moment | null
  refreshToken: string | null
  refreshTokenValidTo: moment.Moment | null
  fcmToken: string | null
  user: Customer | null
  logoutInProgress = false

  constructor (accessToken?: string | null,
    accessTokenValidTo?: moment.Moment | null,
    refreshToken?: string | null,
    refreshTokenValidTo?: moment.Moment | null,
    fcmToken?: string | null,
    user?: Customer | null) {
    this.accessToken = accessToken || null
    this.accessTokenValidTo = accessTokenValidTo || null
    this.refreshToken = refreshToken || null
    this.refreshTokenValidTo = refreshTokenValidTo || null
    this.fcmToken = fcmToken || null
    this.user = user || null
  }
}
