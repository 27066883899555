<template>
  <div class="container">
    <login-header></login-header>
    <public-page-box>
      <loading id="login" class="mb-3"></loading>
      <b-alert variant="success" :show="registrationSuccess" v-t="'account.activated.info'" class="text-center"></b-alert>
      <b-alert variant="danger" :show="!!error" v-html="$t(error || '')" class="text-center"></b-alert>

      <div class="row">
        <div class="col">
          <button class="btn btn-lg btn-facebook btn-block text-uppercase btn-form" type="button" @click.stop="loginInFacebook()" :disabled="protected()">
            <i class="fab fa-facebook-f mr-2"></i> {{ $t('login.submit.facebook') }}
          </button>
        </div>
      </div>

      <div class="row my-3">
        <div class="col"> <hr> </div>
        <div class="col-auto text-uppercase pt-1 text-muted" v-t="'login.submit.separator'"></div>
        <div class="col"> <hr> </div>
      </div>

      <v-form class="form-signin pb-3" ref="form">
        <text-field field-id="username"
                    label="login.email.label"
                    placeholder="login.email.placeholder"
                    :maxlength="100"
                    :disabled="protected()"
                    v-model.trim="credentials.username"
                    validation="required|min:5|max:100"></text-field>
        <text-field field-id="password"
                    label="login.password.label"
                    placeholder="login.password.placeholder"
                    v-model="credentials.password"
                    :maxlength="20"
                    :disabled="protected()"
                    type="password"
                    validation="required|min:5|max:20"></text-field>
        <button class="btn btn-lg btn-primary btn-block text-uppercase btn-form" :disabled="protected()" type="submit"
                @click.prevent="submit($refs.form)" v-t="'login.submit'">
        </button>
      </v-form>
      <hr>
      <div class="text-center"><router-link :to="{ name: 'passwordResetRequest' }" class="reset-link">{{ $t('password.reset.request.link') }}</router-link></div>
    </public-page-box>
  </div>
</template>
<script lang="ts">

import { COMPANY_PARAM, REDIRECT_TO_PARAM } from '@/config'
import authService from '@/modules/auth/services/authService'
import Component, { mixins } from 'vue-class-component'
import facebookInfoModal from '@/modules/common/components/facebookInfoModal.vue'
import { Getter } from 'vuex-class'
import { jwtDecode } from 'jwt-decode'
import loading from '@/modules/common/components/loading.vue'
import publicPageBox from '@/modules/app/components/publicPageBox.vue'
import submitProtectionMixin from '@/modules/common/mixins/submitProtectionMixin'
import textField from '@/modules/common/components/form/textField.vue'
import validationMixin from '@/modules/common/components/form/validationMixin'
import vForm from '@/modules/common/components/form/vForm.vue'
import LoginHeader from '@/modules/app/components/loginHeader.vue'
import Response from '@/modules/common/domain/response'

@Component({
  components: { LoginHeader, facebookInfoModal, loading, publicPageBox, textField, vForm }
})
export default class Login extends mixins(validationMixin, submitProtectionMixin) {
  credentials = { username: '', password: '', captchaToken: '' }
  error: String | null = null
  registrationSuccess = false
  @Getter('loggedIn', { namespace: 'auth' }) loggedIn!: boolean

  async onSubmit () {
    await this.$store.dispatch('app/loadingDataEnable', 'login')

    await this.$recaptchaLoaded()
    // Execute reCAPTCHA with action "login".
    this.credentials.captchaToken = await this.$recaptcha('login')

    const response = await this.$store.dispatch('auth/logIn', this.credentials) as Response
    if (response.success) {
      await this.$router.push(authService.mainPath(this.$router.currentRoute))
    } else {
      if (response.error !== 'error.auth.login') {
        this.error = response.error
      }
    }
    await this.$store.dispatch('app/loadingDataDisable', 'login')
  }

  async loginInFacebook () {
    await this.protect()
    await this.$store.dispatch('app/loadingDataEnable', 'login')
    await authService.redirectToFacebook(this.$route.query[COMPANY_PARAM] as string)
  }

  async mounted () {
    if (this.$route.query.error) {
      this.error = this.$route.query.error as string
    }
    if (!this.registrationSuccess && this.$route.query.activationCode) {
      this.registrationSuccess = await this.$store.dispatch('auth/sigInConfirmation', this.$route.query.activationCode)
      const decoded = jwtDecode(this.$route.query.activationCode! as string) as any
      // add companyId to url if was passed
      const query = {} as any
      if (decoded.companyUrlName) {
        query[COMPANY_PARAM] = decoded.companyUrlName
        query[REDIRECT_TO_PARAM] = encodeURI(this.$router.resolve({ name: 'passDownload' }).route.path!)
      } else {
        query[REDIRECT_TO_PARAM] = encodeURI(this.$router.resolve({ name: 'passes' }).route.path!)
      }
      await this.$router.push({ query })
    }
  }
}
</script>
<style lang="scss">
  a.reset-link {
    color: $text-muted;
    text-decoration: underline;
  }
</style>
