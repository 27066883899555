import MobileDetect from 'mobile-detect'
import Bowser from 'bowser'

const md = new MobileDetect(window.navigator.userAgent)
const bowser = Bowser.getParser(window.navigator.userAgent)

export default {
  iphone () {
    return md.os() === 'iOS' || md.os() === 'iPadOS'
  },
  mac () {
    return bowser.getOS().name === 'macOS'
  },
  apple () {
    return this.iphone() || this.mac()
  },
  embeddedBrowser () {
    return md.match('FBAN') || md.match('FBAV') || md.match('Instagram')
  }
}
