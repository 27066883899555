import $ from 'jquery'
import { Route } from 'vue-router'

export default () => {
  return (to: Route, from: Route, next: Function) => {
    if ((<any>from.meta!).bodyClass) {
      $('body').removeClass((<any>from.meta!).bodyClass)
    }

    if ((<any>to.meta!).bodyClass) {
      $('body').addClass((<any>to.meta!).bodyClass)
    }
    next()
  }
}
