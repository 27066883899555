<template>
  <div :class="{wrapper: !!loggedIn}">
    <navbar v-if="loggedIn"></navbar>
    <loading id="logout-in-progress" class="mt-4" v-if="logoutInProgress"></loading>
    <router-view v-if="!logoutInProgress"/>
    <facebook-info-modal></facebook-info-modal>
  </div>
</template>
<script lang="ts">
import Component from 'vue-class-component'
import { Getter, State } from 'vuex-class'
import FacebookInfoModal from '@/modules/common/components/facebookInfoModal.vue'
import i18n from '@/i18n'
import loading from '@/modules/common/components/loading.vue'
import navbar from './navbar.vue'
import Vue from 'vue'

@Component({
  components: { FacebookInfoModal, loading, navbar },
  metaInfo: {
    title: i18n.message('page.title')
  }
})
export default class App extends Vue {
  @Getter('loggedIn', { namespace: 'auth' }) loggedIn!: boolean
  @State('logoutInProgress', { namespace: 'auth' }) logoutInProgress!: boolean
}

</script>
<style lang="scss">
  /* must be here because we need to initiate to use scss sources */
</style>
