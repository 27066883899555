import _ from 'lodash'
import * as types from './entityMutationTypes'
import { ActionTree } from 'vuex'
import { Entity } from '@/modules/common/domain/entity'
import EntityCreateParams from '@/modules/common/store/entityCreateParams'
import EntityDeleteParams from '@/modules/common/store/entityDeleteParams'
import EntityService from '@/modules/common/services/entityService'
import EntityState from '@/modules/common/store/entityState'
import EntityFetchParams from '@/modules/common/store/entityFetchParams'
import RootState from '@/store/rootState'

export default <E extends Entity, ES extends EntityState<E>>(service: EntityService<any>, loadingId: string) => {
  const actions: ActionTree<ES, RootState> = {
    async getAll ({ state, dispatch, commit }, entityFetchParams: EntityFetchParams) {
      if (!state.items || state.items.length === 0 || (entityFetchParams && entityFetchParams.force)) {
        await dispatch('app/loadingDataEnable', loadingId, { root: true })
        const fetchParams = entityFetchParams ? entityFetchParams.parameters : null
        const entities = await service.findAll(fetchParams)
        await commit(types.LIST, {
          items: entities,
          parameters: fetchParams
        })
        await dispatch('app/loadingDataDisable', loadingId, { root: true })
      }
    },
    async select ({ dispatch, commit }, params) {
      await commit(types.SELECT, {
        item: null
      })
      dispatch('app/loadingDataEnable', loadingId + '-select', { root: true })
      const entity = await service.find(_.isString(params) ? { uuid: params } : params)
      await commit(types.SELECT, {
        item: entity
      })
      dispatch('app/loadingDataDisable', loadingId + '-select', { root: true })
      return entity
    },
    async refresh ({ state, dispatch }) {
      await dispatch('getAll', new EntityFetchParams(true, state.parameters))
    },
    async refreshSelected ({ state, dispatch }) {
      if (state.selected) {
        await dispatch('select', state.selected.uuid)
      }
    },
    async create ({ dispatch }, entityCreateParams: EntityCreateParams<Entity>) {
      const newEntity = await service.create(entityCreateParams.entity)
      if (newEntity) {
        if (entityCreateParams.doFetchAfter) {
          await dispatch('refresh')
        }
        return newEntity
      } else {
        return false
      }
    },
    async update ({ dispatch }, entityCreateParams: EntityCreateParams<Entity>) {
      const updatedEntity = await service.update(entityCreateParams.entity)
      if (updatedEntity) {
        if (entityCreateParams.doFetchAfter) {
          await dispatch('refresh')
          await dispatch('refreshSelected')
        }
        return updatedEntity
      } else {
        return false
      }
    },
    async clearAll ({ commit }) {
      await commit(types.CLEAR_ALL)
    },
    async clearSelected ({ commit }) {
      await commit(types.CLEAR_SELECTED)
    },
    async delete ({ dispatch }, entityDeleteParams: EntityDeleteParams<E>) {
      const response = await service.delete(entityDeleteParams.entity.uuid)
      if (response) {
        if (entityDeleteParams.doFetchAfter) {
          await dispatch('refresh')
        }
        return true
      } else {
        return false
      }
    }
  }

  return actions
}
