<template>
  <div class="container">
    <div class="row">
      <div class="col-sm-9 col-md-7 col-lg-5 mx-auto">
        <div class="card card-signin my-5">
          <div class="card-body">
            <h5 class="card-title text-center mb-3">{{ $t('login.in-progress') }}</h5>
            <p class="text-center">
              <b-spinner variant="info" label="Spinning"></b-spinner>
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script lang="ts">

import Vue from 'vue'
import validationMixin from '@/modules/common/components/form/validationMixin'
import vForm from '@/modules/common/components/form/vForm.vue'
import loading from '@/modules/common/components/loading.vue'
import textField from '@/modules/common/components/form/textField.vue'
import Component from 'vue-class-component'
import authService from '@/modules/auth/services/authService'
import { COMPANY_PARAM } from '@/config'

@Component({
  components: { vForm, textField, loading },
  mixins: [validationMixin]
})
export default class LoginProgress extends Vue {
  async mounted () {
    // redirected from facebook
    if (this.$route.query.code) {
      const code = this.$route.query.code
      const query = {} as any
      const companyId = this.$route.query.state as string
      if (companyId) {
        query[COMPANY_PARAM] = companyId
      }
      const response = await this.$store.dispatch('auth/loginFacebook', { code, companyId })
      // clear code and state parameters from query
      if (response.success && companyId) {
        this.$router.push({ name: 'passDownload', query }).catch(() => {})
      } else if (response.success) {
        this.$router.push(authService.mainPath(this.$router.currentRoute)).catch(() => {})
      } else {
        query.error = response.error
        await this.$router.push({ name: 'login', query })
      }
    }
  }
}
</script>
