import _ from 'lodash'
import { HttpResponse } from 'vue-resource/types/vue_resource'

const DEFAULT_ERROR_KEY = 'error.data.invalid'

export default class Response {
  data: any | null = null
  success = true
  error: string | null = null

  constructor (error?: string | null, data?: any) {
    this.success = _.isNil(error)
    this.data = data
    this.error = error || null
  }

  static fromHttpResponse (httpResponse: HttpResponse | any) {
    if (httpResponse.ok) {
      return new Response(null, httpResponse.data)
    } else if (httpResponse.data.errors && httpResponse.data.errors.length && httpResponse.data.errors[0].code) {
      return new Response('error.' + httpResponse.data.errors[0].code)
    } else {
      return DEFAULT_ERROR
    }
  }
}

export const OK = new Response()
export const DEFAULT_ERROR = new Response(DEFAULT_ERROR_KEY)
