import authService from '@/modules/auth/services/authService'
import { HttpResponse } from 'vue-resource/types/vue_resource'
import { Store } from 'vuex'
import { VueRouter } from 'vue-router/types/router'

export default (store: Store<any>, router: VueRouter) => {
  return () => {
    // do log out action if 401 is returned
    return function (response: HttpResponse) {
      if (response && response.status === 401) {
        (async () => {
          await store.dispatch('auth/logOut', false)
          await router.push(authService.loginPath(router.currentRoute))
        })()
        location.reload()
      }
    }
  }
}
