import _ from 'lodash'
import EntityService from '@/modules/common/services/entityService'
import PasswordChange from '@/modules/customer/domain/passwordChange'
import customerResource from './customerResource'
import Customer from '../domain/customer'
import runAuthorizedRequest from '@/auth/oauthUtils'

class CustomerService extends EntityService<Customer> {
  async me () {
    const response = await runAuthorizedRequest(() => customerResource.me())
    if (response.ok) {
      return new Customer(response.data)
    } else {
      return null
    }
  }

  async myPasswordChange (uuid: string, credentials: PasswordChange) {
    return await runAuthorizedRequest(() => customerResource.myPasswordChange({ uuid }, _.pickBy(credentials)))
  }

  async persistFcmToken (uuid: string, fcmToken: string, companyUrlName?: string) {
    const data = { token: fcmToken } as any
    if (companyUrlName) {
      data.topic = companyUrlName
    }
    return await runAuthorizedRequest(() => customerResource.storeFcmToken({ uuid }, data))
  }
}

export default new CustomerService(customerResource, Customer)
