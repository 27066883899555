import Vue from 'vue'
import { API_ROOT } from '@/config'

const contextPath = 'customers'

const customActions = {
  me: { method: 'GET', url: API_ROOT + contextPath + '/me' },
  myPasswordChange: { method: 'PUT', url: API_ROOT + contextPath + '/{uuid}/password' },
  storeFcmToken: { method: 'POST', url: API_ROOT + contextPath + '{/uuid}/fcm-token' }
}

export default (<any>Vue).resource(API_ROOT + contextPath + '{/uuid}', {}, customActions)
