import _ from 'lodash'
import moment from 'moment'
import StaticValue from '@/modules/common/values/staticValue'

export const normalize = (value?: string) => {
  return (value || '').toLowerCase().normalize('NFD').replace(/[^\w]/g, '')
}

export const enumToStaticValue = (sourceEnum: any, enumName: string) => {
  const value = _(sourceEnum).keys().map(enumValue => new StaticValue(enumValue, `value.${enumName}.label.${enumValue}`)).value()
  return value
}

export const FORMAT_DATE = (value?: moment.Moment) => {
  return value ? value.format('DD.MM.YYYY') : null
}

export const FORMAT_DATE_TIME = (value?: moment.Moment) => {
  return value ? moment(value).format('DD.MM.YYYY HH:mm') : null
}

export const FORMAT_TIME = (value?: moment.Moment) => {
  return value ? moment(value).format('HH:mm') : null
}

export const DOWNLOAD_BLOB = (blob: any, fileName: string) => {
  const filename = fileName
  const result = window.document.createElement('a')
  result.href = window.URL.createObjectURL(blob)
  result.download = filename
  result.click()
}

/**
 * Formats file size. !!!! Taken from vee-validate library !!!!
 *
 * @param {Number|String} size
 */
export const FORMAT_FILE_SIZE = (size: number): string => {
  const units = ['Byte', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']
  const threshold = 1024
  size = Number(size) * threshold
  const i = size === 0 ? 0 : Math.floor(Math.log(size) / Math.log(threshold))
  // @ts-ignore
  return `${(size / Math.pow(threshold, i)).toFixed(2) * 1} ${units[i]}`
}
