/* eslint-disable no-unused-vars */

import Vue from 'vue'
import { configure, extend, localize, ValidationProvider } from 'vee-validate'
import * as rules from 'vee-validate/dist/rules'
import { validationCustomization, validations as csValidations } from '@/i18n/cs'
import { LOCALE } from '@/config'

export default () => {
  const config = {
    useConstraintAttrs: false, // disable inferred of HTML5 validations
    validity: false // disable HTML5 validations
  }

  // Sets the options.
  configure(config)

  // Register it globally
  Vue.component('ValidationProvider', ValidationProvider)

  extend('confirmed', rules.confirmed)
  extend('email', rules.email)
  extend('length', rules.length)
  extend('max', rules.max)
  extend('min', rules.min)
  extend('regex', rules.regex)
  extend('required', rules.required)

  localize(LOCALE, {
    // @ts-ignore
    messages: csValidations,
    fields: validationCustomization
  })
}
