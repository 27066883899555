import { Store } from 'vuex'
import { HttpOptions } from 'vue-resource/types/vue_resource'
import AuthState from '@/modules/auth/store/authState'
import { LOGIN_URL, REFRESH_TOKEN_URL } from '@/config'

export default (store: Store<any>) => {
  return (request: HttpOptions) => {
    if ([LOGIN_URL, REFRESH_TOKEN_URL].indexOf(request.url!) >= 0) {
      return
    }
    const auth = store.state.auth as AuthState
    const hasAuthHeader = request.headers.has('Authorization')
    if (!hasAuthHeader && auth.accessToken) {
      request.headers.set('Authorization', 'bearer ' + auth.accessToken)
    }
  }
}
