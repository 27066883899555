import { AUTH_STORE_ME, AUTH_STORE_TOKEN, AUTH_LOG_OUT } from '@/modules/auth/store/authMutationTypes'
import { AUTH_STORAGE_KEY } from '@/config'

/**
 * react to AUTH_LOG_IN mutation and store authentication values to localStore
 * react to AUTH_LOG_OUT mutation and remove all authentication data from localStore
 * @param store
 */
const localStoragePlugin = (store: any) => {
  store.subscribe((mutation: any) => {
    const AUTH_MODULE_PREFIX = 'auth/'
    if (mutation.type === (AUTH_MODULE_PREFIX + AUTH_STORE_ME) || mutation.type === (AUTH_MODULE_PREFIX + AUTH_STORE_TOKEN)) {
      localStorage.setItem(AUTH_STORAGE_KEY, JSON.stringify(store.getters['auth/data']))
    } else if (mutation.type === (AUTH_MODULE_PREFIX + AUTH_LOG_OUT)) {
      localStorage.removeItem(AUTH_STORAGE_KEY)
    }
  })
}

export default localStoragePlugin
