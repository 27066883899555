import profilePage from '@/modules/customer/components/profilePage.vue'
import passwordChangePage from '@/modules/customer/components/passwordChangePage.vue'

export default [
  {
    name: 'profile',
    path: '/profile',
    component: profilePage,
    meta: {
      requiresLoggedIn: true
    }
  },
  {
    name: 'passwordChange',
    path: '/password-change',
    component: passwordChangePage,
    meta: {
      requiresLoggedIn: true
    }
  }
]
