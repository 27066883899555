<template>
  <page title="profile.title">
    <div class="row justify-content-center">
      <div class="col">
        <p class="text-center">{{ user.email }}</p>
        <v-form ref="form">
          <text-field field-id="firstname"
                      label="profile.firstname.label"
                      placeholder="profile.firstname.placeholder"
                      :maxlength="100"
                      v-model="user.firstname"
                      validation="required|min:3|max:100"
                      :focus="true"></text-field>
          <text-field field-id="surname"
                      label="profile.surname.label"
                      placeholder="profile.surname.placeholder"
                      :maxlength="100"
                      v-model="user.surname"
                      validation="required|min:3|max:100"
                      :focus="true"></text-field>
          <button class="btn btn-lg btn-primary btn-block text-uppercase btn-form" :disabled="protected()" type="submit"
                  @click.prevent="submit($refs.form)" v-t="'button.submit'">
          </button>
        </v-form>
      </div>
    </div>
  </page>
</template>

<script lang="ts">
import _ from 'lodash'
import Component, { mixins } from 'vue-class-component'
import page from '@/modules/common/components/page.vue'
import vForm from '@/modules/common/components/form/vForm.vue'
import textField from '@/modules/common/components/form/textField.vue'
import validationMixin from '@/modules/common/components/form/validationMixin'
import Customer from '@/modules/customer/domain/customer'
import { State } from 'vuex-class'
import { Watch } from 'vue-property-decorator'
import EntityCreateParams from '@/modules/common/store/entityCreateParams'
import notificationService from '@/modules/common/services/notificationService'

@Component({
  components: { page, textField, vForm }
})
export default class ProfilePage extends mixins(validationMixin) {
  user: Customer | null = null

  @State('user', { namespace: 'auth' }) currentUser!: Customer

  @Watch('currentUser')
  onCurrentUserChange (currentUser?: Customer) {
    if (currentUser) {
      this.user = _.cloneDeep(currentUser)
    }
  }

  async onSubmit () {
    await this.$store.dispatch('customer/update', new EntityCreateParams(this.user!, false))
    await this.$store.dispatch('auth/refreshMe')
    notificationService.success('entity.edit.success')
  }

  created () {
    this.user = _.cloneDeep(this.currentUser)
  }
}
</script>
