<template>
  <div class="content-wrapper">
    <div class="row justify-content-center mt-3 mr-1 ml-1">
      <div class="col-md-8 col-lg-6 col-xl-4">
        <b-card header-bg-variant="white" body-bg-variant="white" :header-class="headerClass" :style="cardStyle">
          <template #header v-if="title || hasHeader">
            <h4 class="text-center mb-0" v-if="title">{{ $t(title) }}</h4>
            <slot name="header" v-if="hasHeader"></slot>
          </template>
          <div class="content">
            <div class="row">
              <div class="col-12">
                <slot></slot>
              </div>
            </div>
          </div>
        </b-card>
      </div>
    </div>
  </div>
</template>
<script lang="ts">

import Vue from 'vue'
import Component from 'vue-class-component'
import { Prop } from 'vue-property-decorator'

@Component
export default class Page extends Vue {
  @Prop({ type: String, required: false }) title?: string
  @Prop({ type: String, required: false, default: '' }) headerClass?: string
  @Prop({ type: String, required: false, default: '' }) cardStyle?: string

  get hasHeader (): boolean {
    return !!this.$slots.header
  }
}
</script>
