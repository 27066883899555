import Vue from 'vue'
import Router from 'vue-router'
import appRoutes from '@/modules/app/routes/appRoutes'
import passRoutes from '@/modules/pass/routes/passRoutes'
import userRoutes from '@/modules/customer/routes/customerRoutes'

Vue.use(Router)

const router = new Router({
  mode: 'hash',
  routes: [
    ...appRoutes,
    ...passRoutes,
    ...userRoutes
  ]
})

export default router
