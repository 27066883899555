<template>
  <div class="container">
    <login-header></login-header>
    <public-page-box>

      <div class="row">
        <div class="col">
          <button class="btn btn-lg btn-primary btn-block text-uppercase btn-form" type="button"
                  @click.prevent="login()" v-t="'login.submit'">
          </button>
        </div>
      </div>

      <div class="row my-3">
        <div class="col">
          <hr>
        </div>
        <div class="col-auto text-uppercase pt-1 text-muted" v-t="'login.submit.separator'"></div>
        <div class="col">
          <hr>
        </div>
      </div>

      <div class="row">
        <p class="col text-center" v-t="'login.register.text'"></p>
      </div>

      <div class="row">
        <div class="col">
          <button class="btn btn-lg btn-success btn-block text-uppercase btn-form" type="button" @click="signUp()">
            <i class="fas fa-envelope mr-2"></i> {{ $t('login.register.email') }}
          </button>
        </div>
      </div>
    </public-page-box>
  </div>
</template>
<script lang="ts">

import Component, { mixins } from 'vue-class-component'
import facebookInfoModal from '@/modules/common/components/facebookInfoModal.vue'
import loading from '@/modules/common/components/loading.vue'
import publicPageBox from '@/modules/app/components/publicPageBox.vue'
import submitProtectionMixin from '@/modules/common/mixins/submitProtectionMixin'
import textField from '@/modules/common/components/form/textField.vue'
import validationMixin from '@/modules/common/components/form/validationMixin'
import vForm from '@/modules/common/components/form/vForm.vue'
import LoginHeader from '@/modules/app/components/loginHeader.vue'
import { COMPANY_PARAM, REDIRECT_TO_PARAM } from '@/config'

@Component({
  components: { LoginHeader, facebookInfoModal, loading, publicPageBox, textField, vForm }
})
export default class Preface extends mixins(validationMixin, submitProtectionMixin) {
  async login () {
    const query: any = {}
    if (this.$route.query[COMPANY_PARAM]) {
      query[COMPANY_PARAM] = this.$route.query[COMPANY_PARAM]
    }
    if (this.$route.query[REDIRECT_TO_PARAM]) {
      query[REDIRECT_TO_PARAM] = this.$route.query[REDIRECT_TO_PARAM]
    }
    await this.$router.push({ name: 'login', query })
  }

  async signUp () {
    const query: any = {}
    if (this.$route.query[COMPANY_PARAM]) {
      query[COMPANY_PARAM] = this.$route.query[COMPANY_PARAM]
    }
    await this.$router.push({ name: 'signUp', query })
  }
}
</script>
