import { ActionTree, Commit } from 'vuex'
import { AUTH_LOG_OUT, AUTH_STORE_ME, AUTH_STORE_TOKEN } from './authMutationTypes'
import authService from '../services/authService'
import AuthState from '@/modules/auth/store/authState'
import Credentials from '@/modules/auth/domain/credentials'
import eventBus from '@/modules/common/services/eventBus'
import moment from 'moment'
import PasswordResetRequestCredentials from '@/modules/auth/domain/passwordResetRequestCredentials'
import PasswordResetCredentials from '@/modules/auth/domain/passwordResetCredentials'
import RootState from '@/store/rootState'
import smartlookService from '@/modules/common/services/smartlookService'
import customerService from '@/modules/customer/services/customerService'
import Response from '@/modules/common/domain/response'

async function processLoginResponse (response: Response, commit: Commit) {
  if (response.success) {
    await storeTokens(response, commit)
    const user = await customerService.me()
    await commit(AUTH_STORE_ME, { user })
    smartlookService.identify(user!.email!)
  }
}

async function storeTokens (response: Response, commit: Commit) {
  if (response.success) {
    const tokenData: any = {
      accessToken: response.data.access_token,
      accessTokenValidTo: moment().add(response.data.expires, 's')
    }
    if (response.data.refresh_token) {
      tokenData.refreshToken = response.data.refresh_token
      tokenData.refreshTokenValidTo = moment().add(response.data.refresh_expires, 's')
    }
    await commit(AUTH_STORE_TOKEN, { ...tokenData })
  }
}

const authActions: ActionTree<AuthState, RootState> = {
  async logIn ({ commit }, credentials: Credentials) {
    const response = await authService.login(credentials)
    await processLoginResponse(response, commit)
    return response
  },
  async refreshToken ({ commit, state }) {
    const response = await authService.refreshToken(state.refreshToken!)
    if (response.success) {
      await storeTokens(response, commit)
    }
    return response
  },
  async loginFacebook ({ commit }, { code, companyId }) {
    const response = await authService.loginFacebook(code, companyId)
    await processLoginResponse(response, commit)
    return response
  },
  // eslint-disable-next-line no-empty-pattern
  async signUp ({}, { credentials, params }) {
    return authService.singUp(credentials, params)
  },
  // eslint-disable-next-line no-empty-pattern
  async sigInConfirmation ({ }, activationCode: string) {
    try {
      return await authService.sigInConfirmation(activationCode)
    } catch (ex) {
      return false
    }
  },
  // eslint-disable-next-line no-empty-pattern
  async resetPassword ({ }, credentials: PasswordResetCredentials) {
    try {
      return await authService.resetPassword(credentials)
    } catch (ex) {
      return false
    }
  },
  // eslint-disable-next-line no-empty-pattern
  async resetPasswordRequest ({ }, credentials: PasswordResetRequestCredentials) {
    // do not catch errors. Transforming to Response object is done inside authService
    return authService.resetPasswordRequest(credentials)
  },
  async refreshMe ({ commit }) {
    try {
      const user = await customerService.me()
      await commit(AUTH_STORE_ME, { user })
      smartlookService.identify(user!.email!)
      return user
    } catch (ex) {
      return false
    }
  },
  async logOut ({ dispatch, commit }, apiLogout = true) {
    if (apiLogout) {
      await authService.logout()
    }
    await commit(AUTH_LOG_OUT)
    await dispatch('app/loadingDataEnable', 'logout-in-progress', { root: true })
    await eventBus.$emit('logout')
  }
}
export default authActions
