import moment from 'moment'
import Labeled from '@/modules/common/values/labeled'

class Entity implements Labeled {
  uuid?: string | null
  created?: moment.Moment | null
  value: any
  label: string
  labelDescription?: string
  searchString?: string

  constructor (data?: any, idProp: string = 'uuid', labelProp: string = 'label', searchProps?: Array<string>) {
    if (data && data.created) {
      this.created = this.convertDate(data.created)
    }
    this.uuid = data.uuid ? data.uuid : null
    this.value = (this as any)[idProp] || ''
    this.label = (this as any)[labelProp] || ''
    this.buildSearchString(searchProps || ['label'])
  }

  convertDate (date: any): moment.Moment | null {
    return date ? moment(date) : null
  }

  private buildSearchString (args: Array<string> = []) {
    this.searchString = ' '
    args.forEach(arg => {
      this.searchString += (((this as any)[arg] || '') + ' ')
    })
    // concat string without whitespaces
    this.searchString += this.searchString.replace(/\s/g, '')
  }
}

export { Entity }
export const GENERATED_PROPS = ['label', 'searchString', 'value']
