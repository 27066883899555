<template>
  <page title="passes.title">
    <div class="row justify-content-center">
      <div class="col-auto">
        <loading :id="['company', 'subscribe']"></loading>
      </div>
      <div class="col-12 justify-content-center" v-if="showNotificationInfo">
        <b-alert variant="primary" show class="text-center"><span class="align-middle" v-html="$t('passes.notification.enable.text')"></span>
          <button class="btn btn-outline-primary ml-1 mt-1 mt-md-0"
                  :disabled="protected()"
                  @click.stop="subscribe()">{{ $t('button.enable') }}</button>
        </b-alert>
      </div>
    </div>
    <div class="row" v-for="company in companies" v-bind:key="company.uuid">
      <div class="col-12">
        <button class="btn-block btn" :style="'background-color: ' + company.bgColor + ';'" @click.prevent="detail(company.urlName)"><img :src="company.logoUrl"></button>
      </div>
    </div>
    <no-records :data="companies" loading-id="company" text="passes.no-record"></no-records>
  </page>
</template>

<script lang="ts">
import Company from '@/modules/company/domain/company'
import Component, { mixins } from 'vue-class-component'
import EntityFetchParams from '@/modules/common/store/entityFetchParams'
import loading from '@/modules/common/components/loading.vue'
import noRecords from '@/modules/common/components/noRecords.vue'
import page from '@/modules/common/components/page.vue'
import { State } from 'vuex-class'
import platformService from '@/modules/common/services/platformService'
import notificationService from '@/modules/common/services/notificationService'
import submitProtectionMixin from '@/modules/common/mixins/submitProtectionMixin'
import Customer from '@/modules/customer/domain/customer'
import webNotificationService from '@/modules/common/services/webNotificationService'

@Component({
  components: { loading, noRecords, page }
})
export default class PassesPage extends mixins(submitProtectionMixin) {
  @State('items', { namespace: 'company' }) companies!: Array<Company>
  @State('user', { namespace: 'auth' }) currentUser!: Customer
  notificationEnableDone = false

  created () {
    this.$store.dispatch('company/getAll', new EntityFetchParams(true))
  }

  get showNotificationInfo () {
    return !platformService.apple() && webNotificationService.available() && !this.notificationEnableDone
  }

  /**
   * Subscribe to notification. It ensures web push notification permission request, subscribe to company topic and sending token to BE.
   */
  async subscribe () {
    await this.protect()
    try {
      const result = await webNotificationService.request()
      await this.$store.dispatch('app/loadingDataEnable', 'subscribe')
      if (result === 'granted') {
        await this.$store.dispatch('customer/subscribeToNotifications', { uuid: this.currentUser.uuid })
        notificationService.success('passes.notification.enable.success')
        this.notificationEnableDone = true
      }
    } finally {
      await this.$store.dispatch('app/loadingDataDisable', 'subscribe')
      await this.unprotect()
    }
  }

  detail (companyUrlName: string) {
    this.$router.push({ name: 'passDetail', params: { companyUrlName } })
  }
}
</script>
