import Company from '@/modules/company/domain/company'
import { Module } from 'vuex'
import actions from '@/modules/common/store/entityActions'
import mutations from '@/modules/common/store/entityMutations'
import getters from '@/modules/common/store/entityGetters'
import RootState from '@/store/rootState'
import EntityState from '@/modules/common/store/entityState'
import EntityService from '@/modules/common/services/entityService'
import companyResource from '@/modules/company/services/companyResource'

const contactModule: Module<EntityState<Company>, RootState> = {
  namespaced: true,
  state: new EntityState(),
  mutations,
  getters,
  actions: actions(new EntityService(companyResource, Company), 'company')
}

export default contactModule
