import authService from '@/modules/auth/services/authService'
import { Store } from 'vuex'
import { Route } from 'vue-router'

const PUBLIC_ROUTES = ['login', 'preface', 'signUp']

export default (store: Store<any>) => {
  return (to: Route, from: Route, next: Function) => {
    if ((<any>to.meta).requiresLoggedIn && !store.getters['auth/loggedIn']) {
      next(authService.loginPath(to))
    } else if (to.name && (PUBLIC_ROUTES.indexOf(to.name) >= 0) && store.getters['auth/loggedIn']) {
      next(authService.mainPath(to))
    } else {
      next()
    }
  }
}
