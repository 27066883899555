<template>
  <div class="form-group">
    <label class="col-form-label" :class="{ required: requiredVal, 'has-value': innerValue }" v-show="showLabel">
      {{ $t(label) }}
    </label>
    <validation-provider :name="fieldId" :rules="validation || validationObj" v-slot="{ errors }" slim mode="lazy">
      <input :type="type"
             :id="fieldId"
             v-model="innerValue"
             :placeholder="placeholder ? $t(placeholder) : ''"
             :maxlength="maxlength"
             class="form-control"
             :class="{ 'is-invalid': errors.length, ...cssClass }"
             v-focus="focus"
             ref="textfield"
             @paste="paste"
             :readonly="readonly"
             :disabled="disabled"
             v-show="showInput"
             :autocomplete="type === 'password' ? 'current-password': ''"
      />
      <p class="text-danger" v-if="errors.length">{{ errors[0] }}</p>
    </validation-provider>
  </div>
</template>
<script lang="ts">
import Component from 'vue-class-component'
import { Prop, Watch } from 'vue-property-decorator'
import { ValidationProvider } from 'vee-validate'
import Vue from 'vue'

@Component({
  components: { ValidationProvider }
})
export default class TextField extends Vue {
  innerValue?: string | null = null

  @Prop({ type: String, default: '' }) value!: string
  @Prop({ type: String, default: 'text' }) type!: string
  @Prop({ type: String }) label?: string
  @Prop({ type: String, default: '' }) placeholder!: string
  @Prop({ type: String, required: false }) cssClass?: string
  @Prop({ type: String, required: true }) fieldId?: string
  @Prop({ type: Boolean, required: false, default: false }) disabled?: boolean
  @Prop({ type: Boolean, required: false }) readonly?: boolean
  @Prop({ type: Number, required: false, default: 100000 }) maxlength?: number
  @Prop({ type: String }) validation!: string
  @Prop({ type: Boolean, default: false }) required!: boolean
  // eslint-disable-next-line
  @Prop({ type: Object, default: () => {} }) validationObj!: any
  @Prop({ type: Boolean, default: false }) focus!: boolean
  @Prop({ type: Boolean, default: true }) showLabel!: boolean
  @Prop({ type: Boolean, default: true }) showInput!: boolean
  @Prop({ type: Boolean, default: false }) showEmoji?: boolean
  @Prop({ type: Boolean, default: false }) emojiRight?: boolean

  paste (e: Event) {
    this.$emit('paste', e)
  }

  get requiredVal () {
    return this.required || ((this.validation || '').indexOf('required') >= 0) || (this.validationObj || {}).required
  }

  @Watch('innerValue')
  onInnerValueChange (value: string) {
    this.$emit('input', value)
  }

  @Watch('value')
  onValueChange (value: string) {
    this.innerValue = value
  }

  created () {
    if (this.value) {
      this.innerValue = this.value
    }
  }
}
</script>
